//import { Controller } from "@hotwired/stimulus"
import { Controller } from 'stimulus'

export default class extends Controller {
  fieldVariables = {};


  connect() {
  }

  aplicarBlocos(){
    var block_array_true = [];
    var block_array_false = [];
    var content = document.getElementById("form_bloco");

    content.querySelectorAll('input[type="checkbox"]').forEach(function(checkbox) {
        if (!checkbox.checked) {
          block_array_false.push(checkbox.name);
        }else{
          block_array_true.push(checkbox.name);
        }
    });

    debugger
    block_array_false.forEach(nomeBloco => {
      this.ocultarBloco(nomeBloco);
    });
    block_array_true.forEach(nomeBloco => {
      this.mostrarBloco(nomeBloco);
    });
    $('#nav-tese-tab').click()
  }


  ocultarBloco(nomeBloco) {
    let dentroBloco = false;
    //var blocoOcultar = $('#input-ocultar').val();
    var blocoOcultar = nomeBloco;
    let conteudo = $('#tese_content').summernote('code');
    let $conteudo = $('<div>').html(conteudo);
  
    $conteudo.find('*').each(function() {
      let texto = $.trim($(this).text());
      if (texto.startsWith("[[inicio_bloco: " + blocoOcultar + "]]")) {
        dentroBloco = true;
        $(this).hide();
      } else if (texto.startsWith("[[fim_bloco: " + blocoOcultar + "]]")) {
        dentroBloco = false;
        $(this).hide();
      } else if (dentroBloco) {
        $(this).hide();
      }
    });
    
    $('#tese_content').summernote('code', $conteudo.html());
  }
  
  mostrarBloco(nomeBloco) {
    let dentroBloco = false;
    //var blocoOcultar = $('#input-ocultar').val();
    var blocoOcultar = nomeBloco;
    let conteudo = $('#tese_content').summernote('code');
    let $conteudo = $('<div>').html(conteudo);
  
    $conteudo.find('*').each(function() {
      let texto = $.trim($(this).text());
      if (texto.startsWith("[[inicio_bloco: " + blocoOcultar + "]]")) {
        dentroBloco = true;
        $(this).show();
      } else if (texto.startsWith("[[fim_bloco: " + blocoOcultar + "]]")) {
        dentroBloco = false;
        $(this).show();
      } else if (dentroBloco) {
        $(this).show();
      }
    });
  
    $('#tese_content').summernote('code', $conteudo.html());
  }
  
  
  
  
  
  
  
  openModal() {
    // Use jQuery para mostrar o modal com base no identificador único
    $('#myModal').modal('show');
  }

  getBlocos(){
    var layoutContent = $('#layout_content').val(); // Obtém o conteúdo HTML do input
    var arrayRes = [];

    // Cria um elemento temporário para manipular o HTML como um documento
    var tempElement = $('<div></div>').html(layoutContent);

    // Procura por todos os inputs dentro de elementos com classe 'bloco' e extrai os IDs
    tempElement.find('.bloco').each(function() {
        var id = $(this).attr('id');
        if (id) {
            arrayRes.push(id);
        }
    });
    debugger
    return arrayRes;  
  }

  openModalTesesGpt() {
    $('#area-text-gpt-modify').val('');
    //nota: atualizar token do usuário assim q modal for carregado.
    const userId = $('#current_user').val()
    fetch(`/users/${userId}.json`)
    .then(response => {
      if (!response.ok) {
        throw new Error('pegar limit');
      }
      return response;
    })
    .then(response => response.json())
    .then(data => {
      console.log(data);
      $('#limit_token').text(data.limit_token)
      //return data.limit_token
    })
    .catch(error => {
      console.error('Erro:', error);
    });

    
    // let selectedText = window.getSelection().toString();
    // let tempElement = document.createElement('div');
    // tempElement.innerHTML = selectedText;
    // let plainText = tempElement.innerText;
    // var textSelected = plainText.replace(/\n/g, '').trim();

    // var textSelected = window.getSelection().toString().replace(/\s+/g, ' ').trim();
    
    var textSelected = window.getSelection().toString().replace(/\n+/g, '\n').trim();

    
    
    if (window.location.pathname.includes('layout_teses')) {
      var textoSummerNote = $('#layout_content').summernote('code');
    }else {
      var textoSummerNote = $('#tese_content').summernote('code');
    }
    

    var htmlContent = textoSummerNote
    var tempDiv = $('<div>').html(htmlContent);    
    var textContent = tempDiv.find('p').map(function() {
      return $(this).text();
    }).get().join('\n');
    var divTexto = textContent.replace(/\n+/g, '\n').trim();

    var novaDiv = $('<textarea>', {
      class: 'textoSelecionadoHTML',
      text: textSelected
    });

    novaDiv.hide();
    $('#gptModal').append(novaDiv);

    // var divTexto = $($('#tese_content').summernote('code')).text();
    
    
    if (textSelected.length == 0) {
      alert('Selecione um texto.');
      return false;
    } 
    
    if (divTexto.includes(textSelected)) {
      //document.execCommand('removeFormat', false, null);

      $('#script-gpt')[0].selectize.clear()
      $('#area-text-gpt-modify').text('');
      $('#gptModal').modal('show');
      $('#area-text-gpt-original').text(textSelected);
    }else {
      alert('Selecione um texto do editor.')
    }
    
  }

  changeToken(userId, tokenRestante){
    debugger
    const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
    fetch('/users/change_token/', {
      method: 'PATCH',
      headers: {
        'Content-Type': 'application/json',
        'X-CSRF-Token': csrfToken 

      },
      body: JSON.stringify({
        id: userId,
        limit_token: tokenRestante
      })
    })
    .then(response => {
      if (!response.ok) {
        throw new Error('Erro ao atualizar o usuário');
      }
      return response.json();
    })
    .then(data => {
      debugger
      console.log('Usuário atualizado com sucesso', data);
    })
    .catch(error => {
      console.error('Erro:', error);
    });
  }

  chatgptText(event) {
    const userId = $('#current_user').val()
    var limitToken = parseInt($('#limit_token').text(), 10);
    if($('#script-gpt').val() == ''){
      alert('Selecione o comando a executar!')
      return;
    }
    if (limitToken <= 0){
      alert('Saldo insuficiente!')
      return
    }

    const keygpt = event.currentTarget.dataset.keygpt;

    var userInput = $('#area-text-gpt-original').val();
    var script = " Aplique o script: " + $('#script-gpt').val().join(' e ') + '. Não reescreva o texto antigo, somente a resposta! No texto abaixo:';

    $('#area-text-gpt-modify').val('');
    $('#spinner-gpt').removeClass('d-none'); //mostra spinner 

    $.ajax({
      url: "https://api.openai.com/v1/chat/completions",
      type: "POST",
      headers: {
        "Content-Type": "application/json",
        "Authorization": `Bearer ${keygpt}`,
      },
      data: JSON.stringify({
        messages: [
          {
            role: "user",
            content: script + userInput
          }
        ],
        //model: "gpt-4-turbo-preview",
        //model: "gpt-4-0125-preview",
        model: "gpt-3.5-turbo",
        max_tokens: null
      }),
      success: (data) => {
        if (data.choices && data.choices.length > 0) {
          $('#area-text-gpt-modify').val('');
          $('#spinner-gpt').addClass('d-none')
          $('#area-text-gpt-modify').val(data.choices[0].message.content)
          
          var tokenGasto = data.usage.total_tokens
          var tokenRestante = limitToken - tokenGasto
          debugger
          this.changeToken(userId, tokenRestante)

          $('#limit_token').text(tokenRestante)
        
          console.log(data)
        } else {
          $('#spinner-gpt').addClass('d-none')
          $('#area-text-gpt-modify').val('Houve algum problema, porfavor verificar saldo, chave e/ou status da OPENIA GPT.');
          console.error("Resposta da API não contém escolhas válidas:", data);
        }
      },
      error: function(error) {
        $('#spinner-gpt').addClass('d-none')

        if(error.responseJSON.error.message.includes('Incorrect API key provided')){
          alert(error.status + ' - Verifique a Key GPT em configurações.');
        }
        else if(error.responseJSON.error.message.includes('You exceeded your current quota, please check your plan and billing details')){
          alert(error.status + ' - Verifique se tem saldo suficiente para fazer uma consulta.');
        }
        else if(error.responseJSON.error.message.includes('Rate limit reached for requests')){
          alert(error.status + ' - Você está enviando solicitações muito rapidamente para OPENIA. Aguarde um momento e tente novamente.');
        }
        else if(error.responseJSON.error.message.includes('The server had an error while processing your request')){
          alert(error.status + ' - Tente novamente sua solicitação após alguns minutos.');
        }

        console.error("Erro ao enviar mensagem:", error);
      }
    });
  };

  applyTextGpt(){
    if($('#area-text-gpt-modify').val() == ''){
      alert('Carregue o script primeiro!');
      return;
    }else{
      //var resultado = confirm('Deseja aplicar o texto? O antigo irá sumir.')

      // if(resultado){
        var original = $('.textoSelecionadoHTML').val();
        var modify = $('#area-text-gpt-modify');
        modify.select();
        document.execCommand('copy');
        //debugger 

        // var newText = $('#tese_content').val().replace(original, modify);
        //$('#tese_content').val(newText);
        // $('#tese_content').summernote('code', newText);
        
        $('#inputOculto').val(original);
        
        $('#gptModal').modal('hide');
        // $('#gptModal').modal('hide').on('hidden.bs.modal', function (e) {
        //   $(this).remove();
        // });
        
        // $('#btn_teste').click();
        // setTimeout(function() {
        //   $('#btn_teste').click();
        // }, 500)
        //$('#gptModal').find('.textoSelecionadoHTML').remove();

      // }
    }
  }
  

  recarregarLayout() {
    const layoutTeseInput = document.getElementById('tese_layout_tese_id');
    const changeEvent = new Event('change');
    layoutTeseInput.dispatchEvent(changeEvent);
  }

  changeCategory(){
    debugger
    var category_id = $('#category-select').val();
    if (category_id) {
      $.ajax({
        url: '/layouts_by_category',
        method: 'GET',
        data: { category_id: category_id },
        dataType: 'json',
        success: function(data) {
          if (data.length > 0){
            var options = '<option value="">Selecione</option>';
            for (var i = 0; i < data.length; i++) {
              options += '<option value="' + data[i].id + '">' + data[i].name + '</option>';
            }
            $('#tese_layout_tese_id').html(options).prop('disabled', false); 
          }else{
            var options = '<option value="">Sem layouts</option>';
            $('#tese_layout_tese_id').html(options).prop('disabled', true);
          }
        },
        error: function(xhr, status, error) {
          console.error('Erro ao buscar os layouts:', error);
        }
      });
    } else {
      $('#layout-select').html('<option value="">Selecione</option>').prop('disabled', true); 
    }
    
  }
  
  changeLayoutTese(event) {
    debugger
    const layoutTeseId = event.target.value;
    if (layoutTeseId) {
      fetch(`/layout_teses/${layoutTeseId}/content`)
        .then(response => response.json())
        .then(data => {
          $('#tese_content_layout').val(data.content);
          //$('#tese_content').val(data.content);
          $('#tese_content').summernote('code', data.content);
          debugger
          // Nota: Atualizar formulário da guia Variáveis
          $('#form_tese').empty();
          data.variable_array.forEach(variable => {
            var variableKey = Object.keys(variable)[0] ?? '';
            var variableValue = Object.values(variable)[0] ?? '';
            const inputField = `<div class="form-group">
                                  <div class="mt-3">
                                    <label>${variableKey}</label>
                                    <input type="text" name="${variableKey}" class="form-control" value="${variableValue}" id="variable_${variableKey}">
                                  </div>
                                </div>`;
            $('#form_tese').append(inputField);
          });
          const saveButton = `<button type="button" class="btn btn-primary mt-3" data-action="click->main#aplicarVariaveis">Aplicar</button>`;
          $('#form_tese').append(saveButton);
          
          // Nota: Atualizar formulário da guia Blocos
          $('#form_bloco').empty();
          let htmlContent = '<div class="row">';
          let block_array = []
          data.block_array.forEach(block => {
            block_array.push(block);
            const inputField = `<div class="col-2 mt-3">
                                  <label for="block_${block}">${block}</label>
                                  <input type="checkbox" name="${block}" id="block_${block}" class="form-check-input">
                                </div>`;
            htmlContent += inputField
            
          });
          htmlContent += '</div>'
          $('#form_bloco').append(htmlContent);
          const saveButtonBloco = `<button type="button" class="btn btn-primary mt-3" data-action="click->main#aplicarBlocos">Aplicar</button>`;
          $('#form_bloco').append(saveButtonBloco);

        })
        .catch(error => console.error('Error:', error));
    } else {
      this.contentLayoutTarget.innerHTML = "";
    }
  }

  aplicarVariaveis() {
    // Obtém todos os campos de entrada do formulário
    const formFields = $('#form_tese').find('input[type="text"]');
    
    // Inicializa um objeto para armazenar os valores dos campos
    let fieldVariables = {};
    
    // Itera sobre os campos de entrada e adiciona seus valores ao objeto fieldVariables
    formFields.each((index, element) => {
      const fieldName = $(element).attr('name');
      const fieldValue = $(element).val();
      fieldVariables[fieldName] = fieldValue;
    });
    
    // Atualiza o campo variable_array com o objeto fieldVariables como JSON
    $('#variable_array').val(JSON.stringify(fieldVariables));
    
    // Obtém o conteúdo HTML do editor Summernote #tese_content
    let teseContent = $('#tese_content').summernote('code');
    
    // Substitui as variáveis no HTML pelo valores correspondentes dos campos de entrada
    $.each(fieldVariables, function(key, value) {
      //if (value.trim() !== '') { // só substitui se tiver algum valor na variável
        // Usar expressão regular para capturar o nome da variável e qualquer sufixo
        var regex = new RegExp('{{' + key + '(?::[^}]+)?}}', 'g');
        
        // Substituir todas as ocorrências da variável no HTML
        teseContent = teseContent.replace(regex, value);
      //}
    });
    
    debugger
    let $conteudo = $('<div>').html(teseContent);
    // Atualiza o conteúdo do editor Summernote #tese_content com o HTML modificado
    $('#tese_content').summernote('code', $conteudo.html());
    
    // Ativa a aba da tese
    $('#nav-tese-tab').click();
  }
  
  
    
  

  clearSearch5(event) {
    debugger
    const searchInput = $('input[name="search[pesquisa]"]');
    if (searchInput.length > 0) {
      searchInput.val('').focus(); 
    }
  }  

  clearSearch4(event) {
    const searchField = event.target.closest("form").querySelector("input[name='search[pesquisa]']");
    if (searchField) {
      searchField.value = "";
    }
  }

  clearSearch3() {
    const searchField = document.getElementById("search_pesquisa");
    if (searchField) {
      searchField.value = "";
    }
  }

  clearSearch2() {
    this.element.querySelector("input[name='search[pesquisa]']").value = "";
  }

  clearSearch(event) {
    debugger
    const { form } = event.currentTarget;
    if (!form) return;

    for (let element of form.elements) {
      switch (element.tagName) {
        case "INPUT":
        case "SELECT":
        case "TEXTAREA":
          if (element.type === "hidden") continue;
          element.value = "";
          break;
      }
    }
  }

  searchCEP(event) {
    const input_cep = event.target;
    const cep = input_cep.value.replace(/[^0-9]/g, '')
    if ( cep == '' || cep.length != 8 ) {
      input_cep.value = ''
      input_cep.focus();
      alert('Favor informar um CEP válido');
    } else if ( ((parseInt(cep) - 60000000) * (parseInt(cep) - 61599999) <= 0) == false) {
      input_cep.value = '';
      input_cep.focus();
      alert('Este programa de entrega de medicamentos somente é permitida para o município de Fortaleza.');
    } else {
      fetch("/busca_cep/" + cep, {
        method: 'GET',
        headers: {
          'Content-Type': 'application/json;charset=utf-8'
        }
      }).then(data => {
        return data.json();
      }).then(endereco_completo => {
        if(endereco_completo != '' && Object.keys(endereco_completo.endereco).length > 0) {
          document.getElementById("paciente_rua").value         = endereco_completo.endereco.address;
          document.getElementById("paciente_bairro").value      = endereco_completo.endereco.neighborhood;
          debugger;
          console.log(endereco_completo.endereco.complement);
          document.getElementById("paciente_complemento").value = endereco_completo.endereco.complement === undefined ? '' : endereco_completo.endereco.complement;
          document.getElementById("paciente_cidade").value      = endereco_completo.endereco.city;
          document.getElementById("paciente_estado").value      = endereco_completo.endereco.state;
          
          document.getElementById("paciente_numero_casa").focus();
        }
        else {
          alert('Endereço não encontrado!')
        }
      });;
    }
  }

  chatgpt(event) {
    $('#chat-container').text('');
    $('#sppiner-grafo').removeClass('d-none');
    $('#btn-enviar-grafo').addClass('disabled');

    const key = event.currentTarget.dataset.key;
    const prompt_grafotecnico = event.currentTarget.dataset.promptGrafotecnico;
    const userId = event.currentTarget.dataset.userId
    const limitToken = parseInt(event.currentTarget.dataset.limitToken)
    const controller = this;

    function appendMessage(message) {
      var messageElement = $('<div class="mt-4">').html(message);
      $('#chat-container').append(messageElement);
    }

    if(limitToken <= 0){
      appendMessage(alertMsg('Saldo de tokens insuficiente. Contate um administrador.'));
      $('#sppiner-grafo').addClass('d-none');
      $('#btn-enviar-grafo').removeClass('disabled');
      return;
    }
    
    if(prompt_grafotecnico == undefined || prompt_grafotecnico == '' || prompt_grafotecnico == null){
      appendMessage(alertMsg('Verifique o prompt em configurações!' ));
      $('#sppiner-grafo').addClass('d-none');
      $('#btn-enviar-grafo').removeClass('disabled');
      return;
    }
  
    var inputImgOriginal = $('#input-img-original')[0].files[0];
    var inputImgContestada = $('#input-img-contestada')[0].files[0];
    
    if (inputImgOriginal && inputImgContestada) {
      var inputNomeCliente = $('#input-nome-cliente').val();
      const reader1 = new FileReader();
      reader1.onload = function(e1) {
        const base64String1 = e1.target.result.split(',')[1]; 
        const reader2 = new FileReader();
        reader2.onload = function(e2) {
          const base64String2 = e2.target.result.split(',')[1]; 
          
          sendToOpenAI(base64String1, base64String2, inputNomeCliente);
          
        }
        reader2.readAsDataURL(inputImgContestada);
      }
      reader1.readAsDataURL(inputImgOriginal);
    } else {
      appendMessage(alertMsg('Precisa selecionar as duas imagens!'));
      console.error("Falha ao ler o arquivo.");
      $('#sppiner-grafo').addClass('d-none');
      $('#btn-enviar-grafo').removeClass('disabled');
    }

    function alertMsg(message) {
      const div = `
        <div class="alert alert-danger alert-dismissible fade show" role="alert">
        ${message}
          <button type="button" class="btn-close" data-bs-dismiss="alert" aria-label="Close"></button>
        </div>
        `;
      return div;
    }
    
  
    function sendToOpenAI(base64String,base64String2, cliente) {
      $.ajax({
        url: "https://api.openai.com/v1/chat/completions",
        type: "POST",
        headers: {
          "Content-Type": "application/json",
          "Authorization": `Bearer ${key}`,
        },
        data: JSON.stringify({
          model: "gpt-4-turbo",
          messages: [
            {
              role: "user",
              content: [
                {
                  type: "text",
                  text: `${prompt_grafotecnico.replace("{{nome_cliente}}", cliente)}`
                },
                {
                  type: "image_url",
                  image_url: {
                    url: `data:image/jpeg;base64,${base64String}`
                  }
                },
                {
                  type: "image_url",
                  image_url: {
                    url: `data:image/jpeg;base64,${base64String2}`
                  }
                }
              ]
            }
          ]
        }),
        success: function(data) {

          if (data.choices && data.choices.length > 0) {
            //appendMessage(`${prompt_grafotecnico.replace("{{nome_cliente}}", cliente)}`, "GigaLaw: ");
            $('#sppiner-grafo').addClass('d-none');
            $('#btn-enviar-grafo').removeClass('disabled');

            var tokenGasto = data.usage.total_tokens
            var tokenRestante = limitToken - tokenGasto
          
            controller.changeToken(userId, tokenRestante)

            $('#tokens-user-grafo').text(tokenRestante)
            $('#btn-enviar-grafo')[0].dataset.limitToken = tokenRestante; //atualiza o limitoken dentro do btn para não deixar usar dinvo sem saldo

            if(tokenRestante < 0){
              $('#div-saldo-token').addClass('text-danger');
            }

            appendMessage(data.choices[0].message.content);
            //console.log(data);
          } else {
            $('#sppiner-grafo').addClass('d-none');
            $('#btn-enviar-grafo').removeClass('disabled');
            console.error("Resposta da API não contém escolhas válidas:", data);
          }
        },
        error: function(error) {
          $('#sppiner-grafo').addClass('d-none');
          $('#btn-enviar-grafo').removeClass('disabled');

          if(error.responseJSON.error.message.includes('Incorrect API key provided')){
            appendMessage(alertMsg(error.status + ' - Verifique a Key GPT em configurações.'));
          }
          else if(error.responseJSON.error.message.includes('You exceeded your current quota, please check your plan and billing details')){
            appendMessage(alertMsg(error.status + ' - Verifique se tem saldo suficiente para fazer uma consulta.'));
          }
          else if(error.responseJSON.error.message.includes('Rate limit reached for requests')){
            appendMessage(alertMsg(error.status + ' - Você está enviando solicitações muito rapidamente para OPENIA. Aguarde um momento e tente novamente.'));
          }
          else if(error.responseJSON.error.message.includes('The server had an error while processing your request')){
            appendMessage(alertMsg(error.status + ' - Tente novamente sua solicitação após alguns minutos.'));
          }

          //console.error("Erro ao enviar mensagem:", error);
        }
      });

      
    }
  }
  
  clearGrafotecnico() {
    const inputs = this.element.querySelectorAll("input");
    inputs.forEach(input => {
      input.value = "";
    });
    $('#chat-container').text('');
  }
  

  
}