import { Controller } from "@hotwired/stimulus";
import Rails from "@rails/ujs";

export default class extends Controller {
  static targets = ["officeSelect", "categorySelect"];

  connect() {
    //console.log("Office Select Target:", this.officeSelectTarget); // Remove this log
    this.loadCategories('1');
  }

  changeOffice(event) {
    this.loadCategories(event.target.value);
  }

  async loadCategories(selectedOfficeId = null) {
    const response = await fetch(`/users/${selectedOfficeId}/categories`, {
      headers: {
        Accept: "text/vnd.turbo-stream.html",
      },
    });

    if (response.ok) {
      const data = await response.json();
      debugger
     
      const optionsHtml = data.map(category => ` <div class="item" data-value="${category.id}">${category.name}<a href="javascript:void(0)" class="remove" tabindex="-1" title="Remove">×</a></div>`).join('');
      //const optionsHtml = data.map(category => `<option value="${category.id}">${category.name}</option>`).join('');
      //this.categorySelectTarget.querySelector('select').innerHTML = optionsHtml;
      this.categorySelectTarget.querySelector('select').innerHTML = optionsHtml;
      debugger
    }
  }
}
