const { Tooltip } = require("bootstrap");

document.addEventListener("turbolinks:load", () => {
  var currentUrl = window.location.pathname;
  var bottons = []
  if (currentUrl.includes('layout_teses') && (currentUrl.includes('new') || currentUrl.includes('edit')) ) {
    bottons = [
      // Grupo de estilos de texto
      ['style', ['bold', 'italic', 'underline', 'strikethrough']],
      // Grupo de estilos de fonte
      ['fontname', ['fontname', 'clear']], // Adicionando a seleção de fonte
      ['fontsize', ['fontsize']], // Adicionando a seleção de tamanho de fonte
      ['color', ['forecolor', 'backcolor']], // Grupo de estilos de cor
      // Grupo de alinhamento de texto
      ['para', ['ul', 'ol', 'paragraph', 'height']],
      // Grupo de estilos de inserção de mídia
      ['insert', ['picture', 'pagebreak','table']],
      ['print', ['imprimir', 'show']],
      // Grupo de estilos de outros
      ['misc', ['codeview','undo','redo']],
      ['text', ['addTextInput','customButton']],
      ['text', ['addTextBloco','buttonAbrirBloco']],
      ['cr', ['cabecalho','rodape']],
    ]
  }else{
    bottons = [
      // Grupo de estilos de texto
      ['style', ['bold', 'italic', 'underline', 'strikethrough']],
      // Grupo de estilos de fonte
      ['fontname', ['fontname', 'clear']], // Adicionando a seleção de fonte
      ['fontsize', ['fontsize']], // Adicionando a seleção de tamanho de fonte
      ['color', ['forecolor', 'backcolor']], // Grupo de estilos de cor
      // Grupo de alinhamento de texto
      ['para', ['ul', 'ol', 'paragraph', 'height']],
      // Grupo de estilos de inserção de mídia
      ['insert', ['picture', 'pagebreak','table']],
      // Grupo de estilos de outros
      ['misc', ['codeview','undo','redo']],
      ['print', ['imprimir', 'show']],
      ['cr', ['cabecalho','rodape']],
    ]    
  }
  
  $('.summernote').summernote({
    lang: 'pt-BR', // Idioma
    height: 500,   // Altura do editor
    toolbar: bottons,
    fontNames: [
      'sans-serif','Arial', 'Arial Black', 'Courier New', 'Helvetica Neue', 'Helvetica', 'Impact', 
      'Lucida Grande', 'Tahoma', 'Times New Roman', 'Verdana', 'Merriweather', 'Roboto Slab', 
      'Playfair Display', 'Lora', 'Noto Serif', 'Libre Baskerville', 'Cardo', 'PT Serif', 'IBM Plex Serif'
    ], // Adicionando novas fontes
    fontNamesIgnoreCheck: [
      'Merriweather', 'Roboto Slab', 'Playfair Display', 'Lora', 'Noto Serif', 'Libre Baskerville', 
      'Cardo', 'PT Serif', 'IBM Plex Serif'
    ], // Ignorando a verificação da fonte personalizada
    lineHeights: ['0.2', '0.3', '0.4', '0.5', '0.6', '0.8', '1.0', '1.2', '1.4', '1.5', '2.0', '3.0'],
    fontsize: ['8', '9', '10', '11', '12', '14', '16', '18', '20', '22', '24', '26', '28', '30', '32', '34', '36', '48'],
    buttons: {
      customButton: function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
          className: 'note-btn-custom',
          contents: '<b>+</b>',
          click: function () {
            if ($('#inputVariavel').val().length == 0) {
              alert('Informe o nome da variável!')
              return
            }
            var $note = context.layoutInfo.note;
            var texoVariavel = $('#inputVariavel').val();
            var texoVariavelPadrao = $('#inputVariavelPadrao').val();
            
            if (texoVariavelPadrao.trim() == ''){
              $note.summernote('insertText', '{{' + texoVariavel + '}}');
            }else{
              $note.summernote('insertText', '{{' + texoVariavel + ':' + texoVariavelPadrao + '}}');
            }
          }
        });
         // Renderiza o botão
         var $button = $(button.render());

         // Adiciona o atributo 'title' para o tooltip
         $button.attr('title', 'Adicionar variável');
 
         // Inicializa o tooltip
         $button.tooltip({
             container: 'body'
         });
 
         // Re-inicializa todos os tooltips no Summernote
         context.layoutInfo.editor.find('[data-toggle="tooltip"]').tooltip();
 
         return $button;
      },
      addTextInput: function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
          className: 'note-btn-text',
          contents: '<input type="text" placeholder="Nome da variável" id= "inputVariavel" style="width:120px"><input type="text" placeholder="Valor padrão" id= "inputVariavelPadrao" style="width:120px">'
        });
        return button.render();
      },
      imprimir: function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
          className: 'note-btn-print',
          contents: '<i class="bi bi-printer"></i>',
          click: function () {
            //debugger
            $('#btn-imp-pdf').click();

            // if (currentUrl.includes('new') ) {
            //     if (confirm('Clique em ok e aguarde enquanto o sistema irá gerar o PDF da impressão.')) {
            //       $('#modal-carregar-imp').modal('show');
            //       const csrfToken = document.querySelector('meta[name="csrf-token"]').getAttribute('content');
            //       const teseContent = $('#layout_content').val() == undefined ? $('#tese_content').summernote('code') : $('#layout_content').summernote('code')
            //       const cabecalho = $('#flexSwitchCheckDefaultCabecalho').prop('checked')
            //       const rodape = $('#flexSwitchCheckDefaultRodape').prop('checked')
            //       const layoutId = $('#tese_layout_tese_id').val()
            //       const teseName = $('#tese_name').val()
            //       debugger
            //       fetch('/gerar_pdf', {
            //         method: 'POST',
            //         headers: {
            //           'Content-Type': 'application/json',
            //           'X-CSRF-Token': csrfToken
            //         },
            //         body: JSON.stringify({ content: teseContent, format: "pdf", cabecalho: cabecalho, rodape: rodape, layout_id: layoutId, tese_name: teseName })
            //       }).then(response => {
            //         debugger
            //         if (response.ok) {
            //           return response.blob();
            //         } else {
            //           throw new Error('PDF generation failed');
            //         }
            //       }).then(blob => {
            //         debugger
            //         const url = URL.createObjectURL(blob);
            //         window.open(url, '_blank');
            //         $('#modal-carregar-imp').modal('hide');
            //       }).catch(error => {
            //         console.error('Error:', error);
            //       });
            //     }
            //     }else{
            //       $('#btn-imp-pdf').click();
            //     }

          }
        });
        // Renderiza o botão
          var $button = $(button.render());

          // Adiciona o atributo 'title' para o tooltip
          $button.attr('title', 'Imprimir');

          // Inicializa o tooltip
          $button.tooltip({
              container: 'body'
          });

          // Re-inicializa todos os tooltips no Summernote
          context.layoutInfo.editor.find('[data-toggle="tooltip"]').tooltip();

          return $button;
      },
      show: function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
            className: 'note-btn-show',
            contents: '<i class="bi bi-arrows-fullscreen"></i>',
            click: function () {
                var $note = context.layoutInfo.note;
                // Obtém o conteúdo HTML do Summernote
                var conteudoHTML = $note.summernote('code');
                
                // Abre um modal com a visualização do conteúdo HTML
                var modal = window.open('', '_blank', 'height=900,width=800');
                modal.document.write('<html><head><title>Visualizar Documento</title></head><body>');
                modal.document.write(conteudoHTML);
                modal.document.write('</body></html>');
            }
        });
        // Renderiza o botão
        var $button = $(button.render());

        // Adiciona o atributo 'title' para o tooltip
        $button.attr('title', 'Visualizar');

        // Inicializa o tooltip
        $button.tooltip({
            container: 'body'
        });

        // Re-inicializa todos os tooltips no Summernote
        context.layoutInfo.editor.find('[data-toggle="tooltip"]').tooltip();

        return $button;
      },
      pagebreak: function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
            className: 'note-btn-print',
            contents: '<i class="bi bi-file-earmark-break"></i>',
            click: function () {
              var $note = context.layoutInfo.note;
              $note.summernote('pasteHTML', '<div class="text-center" style="page-break-before: always; margin-bottom: 150px" >***QUEBRA DE PÁGINA***</div>');
            }
        });
        // Renderiza o botão
        var $button = $(button.render());

        // Adiciona o atributo 'title' para o tooltip
        $button.attr('title', 'Adicionar quebra de página');

        // Inicializa o tooltip
        $button.tooltip({
            container: 'body'
        });

        // Re-inicializa todos os tooltips no Summernote
        context.layoutInfo.editor.find('[data-toggle="tooltip"]').tooltip();

        return $button;
      },
      cabecalho: function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
          className: 'note-btn-cabecalho',
          contents: `<div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefaultCabecalho" checked>
                      <label class="form-check-label" for="flexSwitchCheckDefaultCabecalho">Cabeçalho</label>
                    </div>`,
          click: function () {
            var imgCabecalho = $('#img-cabecalho').val();

            if (imgCabecalho == 'sem_img'){
              alert('Não existe imagem de cabeçalho salva em configurações!')
            }
        
          }
        });
         // Renderiza o botão
         var $button = $(button.render());

         // Adiciona o atributo 'title' para o tooltip
         $button.attr('title', 'Adicionar cabeçalho');
 
         // Inicializa o tooltip
         $button.tooltip({
             container: 'body'
         });
 
         // Re-inicializa todos os tooltips no Summernote
         context.layoutInfo.editor.find('[data-toggle="tooltip"]').tooltip();
 
         return $button;
      },
      rodape: function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
          className: 'note-btn-rodape',
          contents: `<div class="form-check form-switch">
                      <input class="form-check-input" type="checkbox" id="flexSwitchCheckDefaultRodape" checked>
                      <label class="form-check-label" for="flexSwitchCheckDefaultRodape">Rodapé</label>
                    </div>`,
          click: function () {
            var imgRodape = $('#img-rodape').val();

            if (imgRodape == 'sem_img'){
              alert('Não existe imagem de rodapé salva em configurações!')
            }
        
          }
        });
         // Renderiza o botão
         var $button = $(button.render());

         // Adiciona o atributo 'title' para o tooltip
         $button.attr('title', 'Adicionar roodapé');
 
         // Inicializa o tooltip
         $button.tooltip({
             container: 'body'
         });
 
         // Re-inicializa todos os tooltips no Summernote
         context.layoutInfo.editor.find('[data-toggle="tooltip"]').tooltip();
 
         return $button;
      },
      buttonAbrirBloco: function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
          className: 'note-btn-custom',
          contents: '<b>Abrir bloco</b>',
          click: function () {
            debugger
            if ($('#nomeBloco').val().length == 0) {
              alert('Informe o nome do bloco!')
              return
            }
            if ( $('#layout_content').val().includes('inicio_bloco: ' +  $('#nomeBloco').val()) ) {
              alert('Esse nome de bloco já existe, informe outro!')
              return
            }
            var $note = context.layoutInfo.note;
            var nomeBloco = $('#nomeBloco').val();
            var html = '<p>[[inicio_bloco: ' + nomeBloco + ']]</p><p>Digite o texto do seu bloco aqui ...</p><p>[[fim_bloco: ' + nomeBloco + ']]</p>';
            $note.summernote('pasteHTML', html);
            
            //$note.summernote('pasteHTML', "<br><br><p class= 'bloco' id=id_bloco_" + nomeBloco+ ">[[inicio_bloco: " + nomeBloco + "]] Digite o texto do seu bloco aqui ... [[fim_bloco: " + nomeBloco + "]]</p><br><br>");
          }
        });
         // Renderiza o botão
         var $button = $(button.render());

         // Adiciona o atributo 'title' para o tooltip
         $button.attr('title', 'Adicionar bloco');
 
         // Inicializa o tooltip
         $button.tooltip({
             container: 'body'
         });
 
         // Re-inicializa todos os tooltips no Summernote
         context.layoutInfo.editor.find('[data-toggle="tooltip"]').tooltip();
 
         return $button;
      },
      buttonFecharBloco: function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
          className: 'note-btn-custom',
          contents: '<b>Abrir bloco</b>',
          click: function () {
            if ($('#nomeBloco').val().length == 0) {
              alert('Informe o nome do bloco!')
              return
            }
            var $note = context.layoutInfo.note;
            var texoVariavel = $('#nomeBloco').val();
            $note.summernote('insertText', ' [[fim_bloco: ' + texoVariavel + ']]');
          }
        });
        return button.render();
      },
      addTextBloco: function (context) {
        var ui = $.summernote.ui;
        var button = ui.button({
          className: 'note-btn-text',
          contents: '<input type="text" placeholder="Nome do bloco" id= "nomeBloco">'
        });
        return button.render();
      }      
    }
  });
});
