import { Controller } from 'stimulus';

export default class extends Controller {
  static targets = ['office', 'category', 'spinner'];

  connect() {
   
    // Inicializa o Selectize no elemento
    debugger
    this.selectize = $(this.categoryTarget).selectize()[0].selectize;

    // Verifica se há um valor inicial para o office_id e carrega as categorias correspondentes
    if (this.officeTarget.value) {
      this.loadCategories(this.officeTarget.value);
    } else {
      this.selectize.disable();
    }
  }

  changeOffice() {
    const officeId = this.officeTarget.value;
    if (officeId) {
      this.loadCategories(officeId);
    } else {
      this.selectize.disable();
      this.selectize.clearOptions();
    }
  }

  loadCategories(officeId) {
    this.spinnerTarget.classList.remove('d-none');
    this.selectize.clearOptions();
    $.ajax({
      url: '/office_by_category',
      method: 'GET',
      data: { office_id: officeId },
      dataType: 'json',
      success: (data) => {
        this.spinnerTarget.classList.add('d-none');
        if (data.length > 0) {
          const options = data.map(category => ({ value: category.id, text: category.name }));
          this.selectize.addOption(options);

          // Se uma categoria já estiver selecionada, mantenha-a selecionada
          const selectedCategories = this.categoryTarget.dataset.selected.split(',');
          this.selectize.setValue(selectedCategories);

          this.selectize.enable();
        } else {
          this.selectize.disable();
          this.selectize.clearOptions();
        }
      },
      error: (xhr, status, error) => {
        this.spinnerTarget.classList.add('d-none');
        console.error('Erro ao buscar as categorias:', error);
        this.selectize.disable();
        this.selectize.clearOptions();
      }
    });
  }
}
